import { BOOKING_TYPE } from "./booking.dto";
import { PromoteCodeStatus } from "./invoice.dto";

export enum PROMOTE_USER_TYPE {
    NEW_USERS = 'NewUser',
    ALL_USERS = 'All',
}

export enum DISCOUNT_TYPE {
    FIXED = 'fixed',
    PERCENT = 'percent',
}

export enum PromoteDateRange {
    indefinite = 'indefinite',
    _3MONTH = '3m',
    _6MONTH = '6m',
    _12MONTH = '12m',
    custom = 'custom'
}

export interface PromoDto {
    id: string;
    code: string;
    discountType: DISCOUNT_TYPE;
    discountValue: number;
    selectedCategoriesOnly: boolean;
    dateRangeType: PromoteDateRange;
    fromDate?: string;
    toDate?: string;
    serviceType: BOOKING_TYPE | "";
    userType: PROMOTE_USER_TYPE;
    categories: PromoteCategory[];
    maxNoOfUses: number;
}

export interface UpdatePromoBodyDto {
    code: string;
    discountType: DISCOUNT_TYPE;
    discountValue: number;
    selectedCategoriesOnly: boolean;
    dateRangeType: PromoteDateRange;
    fromDate?: string;
    toDate?: string;
    serviceType: BOOKING_TYPE | "";
    userType: PROMOTE_USER_TYPE;
    categories: PromoteCategory[];
    maxNoOfUses: number;
}

export interface PromoteCategory {
    serviceCategoryId: string;
    serviceId: string;
}

export interface PromoCodeFilterQuery {
    offset?: number;
    limit?: number;
    search?: string;
    status?: PromoteCodeStatus; 
    bookingType?: BOOKING_TYPE;
    userType?: PROMOTE_USER_TYPE;
    sortBy?: string;
    sortType?: string;
  }

export interface PromoCodeUsed {
    accountId: string;
    bookingId: string;
    code: string;
    createdAt: string;
    id: string;
    isDeleted: boolean;
    modifiedAt: string;
    promoCode: {
        categories: PromoteCategory[];
        discountType: DISCOUNT_TYPE;
        discountValue: number;
        selectedCategoriesOnly: boolean;
    }
}
